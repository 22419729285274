import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
// import ReactPlayer from 'react-player'

import styled from '@emotion/styled'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

import BlockContent from '../components/portableText'

// import VideoMp4 from '../videos/thaheifers-drone-video.mp4'
import VideoWebM from '../videos/thaheifers-drone-video.webm'

import Ground from '../images/footer-background-small.jpg'

const FactWrapper = styled.div`
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.92);
  z-index: 3;
  text-align: center;
  width: 100%;
  bottom: 38px;
  margin: 0 auto;
  background-image: url(${Ground});
  top: -6px;
  position: relative;
  padding: 2rem 0;
  @media (min-width: 860px) {
    background-image: none;
    bottom: 38px;
    top: unset;
    position: absolute;
    padding: 0;
  }
  .fact--inner-wrapper {
    max-width: 800px;
    margin: 0 auto 3rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .fact {
    width: 50%;
    font-size: 1rem;
    @media (min-width: 860px) {
      font-size: 1.5rem;
    }
  }
  h2 {
    margin: 0;
    font-size: 2.6rem;
    @media (min-width: 860px) {
      font-size: 4rem;
    }
  }
  h3 {
      font-size: 2rem;
      margin: 0;
      @media (min-width: 860px) {
        font-size: 3rem;
      }
    }
    .subfact {
      margin: 2rem 0;
      font-size: 1rem;
      padding: 0 1rem;
      @media (min-width: 860px) {
        font-size: 1.2rem;
      }
    }
`

export default class Index extends React.Component {
  render () {
    return (
      <Layout>

        <Container>
          <StaticQuery
            query={graphql`
            query sanityHome {
              sanityHome {
                title
                _rawBody
                _rawTheFacts
                theFacts {
                    title
                    firstFactFigure
                    firstFactFigureDescription
                    secondFactFigure
                    secondFactFigureDescription
                    factText {
                      sanityChildren {
                        text
                      }
                    }
                  }
                seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
                    }
                  }
                }
              }
            }
          `}
            render={data => (
              console.log({data}),
                <>
                {data.sanityHome.seoSettings &&
                data.sanityHome.seoSettings.title &&
                data.sanityHome.seoSettings.description &&
                 (
                   <SEO
                     title={data.sanityHome.seoSettings.title}
                     description={data.sanityHome.seoSettings.description}
                     ogimage={data.sanityHome.seoSettings.openGraphImage && data.sanityHome.seoSettings.openGraphImage.asset ? data.sanityHome.seoSettings.openGraphImage.asset.url : null}
                   />
                 )}

                <section className='section'>
                  <div className='extended-content-container'>
                    <div className='grunge-edge first' />

                    <video className='vid' loop autoPlay muted playsInline>

                      <source src='https://player.vimeo.com/external/366925445.hd.mp4?s=72c84fbc8cb62f666806f32d2e91b18b3b3d05b6&profile_id=175' type='video/mp4' />

                      <source src={VideoWebM} type='video/mp4' />
                    </video>

                    <FactWrapper>
                      <h2>{data.sanityHome.theFacts.title}</h2>
                      <div className='fact--inner-wrapper'>

                        <div className='fact'><h3>{data.sanityHome.theFacts.firstFactFigure}</h3><span>{data.sanityHome.theFacts.firstFactFigureDescription}</span></div>

                        <div className='fact'><h3>{data.sanityHome.theFacts.secondFactFigure}</h3><span>{data.sanityHome.theFacts.secondFactFigureDescription}</span></div>
                      </div>
                      <div className='subfact'>
                        {data.sanityHome._rawTheFacts.factText && <BlockContent blocks={data.sanityHome._rawTheFacts.factText} />}
                      </div>
                    </FactWrapper>

                    <div className='grunge-edge second' />
                  </div>
                  <div className='container'>
                    <div className='content'>
                      {data.sanityHome._rawBody && <BlockContent blocks={data.sanityHome._rawBody} />}
                    </div>
                  </div>
                </section>
              </>
            )}
          />
        </Container>
      </Layout>
    )
  }
}
