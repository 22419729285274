import {Link} from 'gatsby'
import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>

    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>
      {children}
    </div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          &copy; {new Date().getFullYear()} The Heifer Authority, LLC, All rights reserved. <br />
          5025 E. CR 82 Carr, CO 80612<br />
          <a href='tel:1-970-978-8971'>(970) 978-8971</a> &bull; <Link to='/contact'>Contact Us</Link>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
