import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

import './figure.module.css'

export default ({node}) => {
  const fluidProps = getFluidGatsbyImage(
    node.asset._ref,
    {maxWidth: 890},
    ...clientConfig.sanity
  )
  return (
    <>
      <figure>
        <Img fluid={fluidProps} alt={node.alt} />
        <figcaption>{node.caption}</figcaption>
      </figure>
    </>
  )
}
