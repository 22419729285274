import {Link, graphql, StaticQuery} from 'gatsby'
import React from 'react'

import Icon from './icon'
import Logo from '../images/logo.svg'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const SubLinks = (props) => {
  return (
    <ul className='sublinks'>
      {props.links.map((sublinks) => (
        <li key={sublinks.link} style={{display: `inline-block`, margin: `0 0 0 1rem`}}>
          <Link to={sublinks.link} >{sublinks.title}</Link>
        </li>
      ))}
    </ul>
  )
}

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => {
  // console.log({onHideNav, onShowNav, showNav, siteTitle})
  return (
    <div className={styles.root}>
      <div className={styles.wrapper} style={{alignItems: `center`}}>
        <div className={styles.branding}>
          <Link to='/'><img src={Logo} alt={siteTitle} /></Link>
        </div>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol='hamburger' />
        </button>

        <StaticQuery
          query={graphql`
            query sanityNav {
              sanityNavigation(id: { eq: "0267287f-f8c5-5f0b-9226-5169b130e6f8" }) {
              links {
                title
                link
                }
              }
            }
          `}
          render={data => (
            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <ul style={{margin: `0 0 0 auto`}}>
                {data.sanityNavigation.links.map((links) => (
                  <li key={links.link}>
                    <Link to={links.link}>{links.title}</Link>
                    {links.links && links.links.length
                      ? <SubLinks links={links.links} />
                      : ''}
                  </li>
                ))}
              </ul>
            </nav>
          )}
        />

      </div>
    </div>
  )
}

export default Header
