import React from 'react'

import {css} from 'emotion'

import getYouTubeID from 'get-youtube-id'

// import styles from './figure.module.css'

function Youtube (props) {
  const id = getYouTubeID(props.url)
  const url = `https://www.youtube.com/embed/${id}`
  if (!id) {
    return <div>Missing YouTube URL</div>
  }
  return (
    <div className={css`position: relative; padding-bottom: 56.25%;`}>
      <iframe
        title='YouTube Preview'
        className={css`position: absolute; top: 0; left: 0; height: 100%; width: 100%; `}
        src={url}
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      />
    </div>
  )
}
export default Youtube
